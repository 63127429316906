import { useEffect, useState } from 'react'
import {
  DATABASE_FIELDS,
  FORM_FIELDS_PREFILLED,
} from '~/components/features/Form/formUtils'
import { useContentfulSettings } from '~/hooks/use-contentful-settings'
import { submitQuizForm, validateForm } from '~/services/formApi'
import { runTracking } from '~/utils/misc'
import { SETTINGS } from '~/utils/settings'

export const useForm = () => {
  const {
    results: { prefillFormFields, disableFormSubmission },
    evaluation: { isEvaluationPageGenerated },
  } = SETTINGS

  const defaultFormData = prefillFormFields
    ? FORM_FIELDS_PREFILLED
    : DATABASE_FIELDS

  const [formData, setFormData] = useState(defaultFormData)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [submitMessage, setSubmitMessage] = useState('')
  const [validationErrors, setValidationErrors] = useState({})
  const settings = useContentfulSettings()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
      cid: localStorage.getItem('localId') ?? '',
    }))
  }

  type handleSubmitParams = {
    shouldValidateData: boolean
    shouldRedirect: boolean
  }

  const handleSubmit = async ({
    shouldValidateData = true,
    shouldRedirect = true,
  }: handleSubmitParams) => {
    if (!!shouldValidateData) {
      const { isValid, errors } = validateForm(formData)

      console.log({ isValid, errors })

      if (!isValid) {
        setValidationErrors(errors)
        setIsSubmitted(false)
        return
      }
    }
    const onSuccess = () => {
      localStorage.setItem(
        'formData',
        JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
        }),
      )
      if (shouldRedirect) {
        setTimeout(() => {
          const redirectUrl = !!isEvaluationPageGenerated
            ? `/${SETTINGS.evaluation.slug}?${formData.answers}&date=${new Date().toISOString()}`
            : `https:${settings.documentToDownload?.file?.url}`
          window.location.href = redirectUrl
        }, 1000)
      }
    }

    if (!disableFormSubmission) {
      try {
        const message = await submitQuizForm(formData)
        setIsSubmitted(true)
        setSubmitMessage(message)

        if (message) {
          runTracking('download')
          onSuccess()
        }
      } catch (error) {
        setIsSubmitted(false)
        setSubmitMessage((error as Error).message)
      }
    }
    if (disableFormSubmission) {
      setIsSubmitted(true)
      setSubmitMessage(`Form submission is disabled`)
      console.log('(you are in test mode) data submited : ', formData)
      onSuccess()
    }
  }
  return {
    formData,
    setFormData,
    isSubmitted,
    submitMessage,
    validationErrors,
    handleChange,
    handleSubmit,
  }
}
