import { SETTINGS } from '~/utils/settings'

export type UserAnswers = (
  | {
      questionIndex: number
      userAnswerIndex: number
    }
  | undefined
)[]

export const convertUserDataFromQueryParamsToObject = () => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search)
    const parsedQueryParams = {
      date: params.get('date'),
      cid: params.get('cid'),
      userAnswers: [...Array(SETTINGS.general.questionsMaxCount).keys()]
        .map((questionIndex) => {
          const param = params.get(questionIndex.toString())
          if (param === null) return
          return {
            questionIndex,
            userAnswerIndex: Number(param),
          }
        })
        .filter((value) => value !== undefined),
    }

    return parsedQueryParams
  } else return null
}

export const convertUserAnswersToQueryParams = (params: UserAnswers) => {
  return params
    ?.map((value) => `${value?.questionIndex}=${value?.userAnswerIndex}`)
    .join('&')
}

export const getUserAnswersFromQueryParamsAsQueryParams = () => {
  const { userAnswers } = convertUserDataFromQueryParamsToObject() || {}
  return userAnswers && convertUserAnswersToQueryParams(userAnswers)
}

// Might be useful for future use
export const convertUserDataToQueryParams = (
  params: Record<string, number | null>,
) => {
  return Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
}
