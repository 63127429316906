import { DATABASE_FIELDS_KEYS } from '~/components/features/Form/formUtils'

const IS_TEST_MODE = false

export const SETTINGS = {
  metadata: {
    siteTitle: `Arrow x Dell x Microsoft - IA`,
    siteDescription: `Gatsby x Contentful Quiz`,
    companyName: `Arrow x Dell x Microsoft`,
    siteBaseUrl: 'https://migration-parc-serveurs.quizz-silicon.fr',
    siteUrl: 'https://migration-parc-serveurs.quizz-silicon.fr',
  },
  general: {
    apiFile: 'saveinfo.php',
    answersMaxCount: 4,
    questionsMaxCount: 8,
  },
  homeAndQuiz: {
    slug: '/' as pagesSlugs,
    bgImagePosition: 'fullWidth' as 'aside' | 'fullWidth',
    bgImageHasShadowCorners: true,
    showQuestionCustomImage: true,
    isAnswerMandatoryToGoToNextQuestion: !IS_TEST_MODE,
  },
  results: {
    slug: 'results' as pagesSlugs,
    isAgnostic: true,
    bgImageHasShadowCorners: true,
    formFieldsToDisplayWithSorting: [
      'lastName',
      'firstName',
      'email',
      'phone',
      'position',
      'company',
    ] as DATABASE_FIELDS_KEYS[],
    prefillFormFields: IS_TEST_MODE,
    disableFormSubmission: IS_TEST_MODE,
  },
  evaluation: {
    isEvaluationPageGenerated: true,
    slug: 'evaluation' as pagesSlugs,
    showEvaluationSheet: false,
    showEvaluationTable: false,
    showSlices: false,
    isAgnostic: true,
    bgImageHasShadowCorners: true,
    showShare: true,
    responseAnalysisLayout: 'linear' as 'imageLeft' | 'linear',
  },
}

export type pagesSlugs = '/' | 'results' | 'evaluation'
